<template>
    <div class="AboutUs">
        <!-- <bread-link></bread-link> -->
        <div class="banner1">
            <div class="title-box">
                <div class="ch-text">关于我们</div>
            </div>
        </div>
        <div class="warpper">
            <div class="content-box">
                <ul class="menu">
                    <li :class="nowType == item.value ? 'active' : ''" v-for="(item,index) in menuList" :key="index" @click="setDesc(item.value)">{{item.name}}</li>
                </ul>
                <div class="main">
                    <pre v-html="descInfo"></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//页面引入swiper
import { getGwCompany } from '@/api/basic'
export default {
    name: 'Industry',
    data() {
        return {
            menuList:[
                {name:'公司简介',value:'companyInfo'},
                {name:'组织架构',value:'organization'},
                // {name:'董事长致辞',value:'speech'},
                {name:'公司领导',value:'companyLeader'},
            ],
            nowType:'companyInfo',
            infoData: [],
            descInfo:'',
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            getGwCompany().then(res => {
                this.infoData = res.data
                this.descInfo = this.infoData.companyInfo;
            })
        },
        setDesc(type){
            this.nowType = type;
            this.descInfo = this.infoData[type]
        }
    },
}
</script>
<style lang="less" scoped>
.AboutUs {
background: #f5f5f5;
    .banner1 {
        width: 100%;
        height: 680px;
        background: url(../../../assets/images/AboutUs/bg_1.png) 50% 50% no-repeat;
        position: relative;
        .title-box {
            width: 1300px;
            text-align: center;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            .en-text {
                font-size: 60px;
            }
            .ch-text {
                font-size: 50px;
                margin-top: 40px;
                position: relative;
                padding-bottom: 20px;
                position: relative;
                font-weight: bold;
            }
            .ch-text:before {
                content: '';
                width: 40px;
                height: 5px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
            }
        }
    }
    .warpper{
        width: 1300px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 0 0 50px 0;
    }
    .content-box {
        display: flex;
        .menu {
            width: 200px;
            background: #fff;
            margin: 0;
            li {
                height: 55px;
                line-height: 55px;
                text-align: center;
                color: #333;
                box-sizing: border-box;
                cursor: pointer;
                border-top: 1px solid #E8E8E8;
                border-left: 1px solid #E8E8E8;
                border-right: 1px solid #E8E8E8;
            }
            li.active {
                background: #ff721d;
                color: #fff;
            }
            li:last-of-type{
                border-bottom: 1px solid #E8E8E8;
            }
            
        }
        pre {
                white-space: pre-wrap;
                word-break: break-all;
            }
        .main{
            width: 1100px;
            padding: 30px 60px;
            background: #fff;
        }
    }
}
</style>
